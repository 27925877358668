.shop-display-ad {
	.app-container.outer-container.text-bottom {
		text-align: right !important;
	}

	.pencil-border {
		border-bottom: 1px solid #e0e6ef !important;
	}

	.ad-text {
		color: #70757D;
		font-family: "Human BBY Digital";
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 12px;
		margin-left: 4px;
	}

	.horizontal-text {
		vertical-align: top;
	}

	.vertical-text {
		writing-mode: vertical-rl;
		margin-bottom: 7px;
		margin-left: 0;
		padding-left: 5px;
		transform: rotate(-180deg);
	}

	.app-container {
		margin: auto;
	}

	.inner-container {
		display: inline-block;
		vertical-align: top;
	}

	.uppercase {
		text-transform: uppercase;
	}

	.pencil-banner {
		.text-none,
		.inner-container {
			width: 970px;
			height: 33px;
		}

		.text-bottom {
			max-width: 970px;
			max-height: 53px;
		}

		.text-left {
			max-width: 990px;
			max-height: 33px;
		}
	}

	.above-header {
		.text-none,

		.outer-container.text-none {
			white-space: nowrap;
		}

		.inner-container {
			// width: 970px;
			min-height: 66px;
		}

		.text-bottom {
			max-width: 970px;
			max-height: 86px;
		}

		.text-left {
			width: 990px;
			min-height: 66px;

			// No longer an image so cannot dynamically size. Set the font in such a way so it fits.
			.ad-text {
				max-height: 66px;
				font-size: 4px;
				margin: 0;
			}
		}
	}

	.footer-ad {
		outer-container {
			white-space: nowrap;
		}
	}

	.above-header-sv {
		.text-none,
		.inner-container {
			width: 320px;
			height: 50px;
		}

		.text-bottom {
			max-width: 320px;
			max-height: 70px;
		}

		.text-left {
			max-width: 340px;
			max-height: 50px;
		}
	}

	.above-header,
	.above-header-sv {
		.app-container {
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}

	.billboard {
		.text-none,
		.inner-container {
			width: 960px;
			height: 90px;
		}

		.text-bottom {
			max-width: 960px;
			max-height: 110px;
		}

		.text-left {
			max-width: 980px;
			max-height: 90px;
		}
	}

	.billboard-tall {
		.text-none,
		.inner-container {
			width: 970px;
			height: 250px;
		}

		.text-bottom {
			max-width: 970px;
			max-height: 270px;
		}

		.text-left {
			max-width: 990px;
			max-height: 250px;
		}
	}

	.custom-leaderboard {
		.text-none,
		.inner-container {
			// width: 970px;
			height: 68px;
			border: 1px solid #E4E5E8;
		}

		.text-bottom {
			max-width: 970px;
			max-height: 86px;
		}

		.text-left {
			max-width: 990px;
			max-height: 66px;
		}
	}

	.leaderboard-footer {
		.text-none,
		.inner-container {
			width: 730px;
			height: 92px;
			border: 1px solid #E4E5E8;
		}

		.text-bottom {
			max-width: 728px;
			max-height: 110px;
		}

		.text-left {
			max-width: 748px;
			max-height: 90px;
		}
	}

	.mdf-medium-rectangle {
		.text-none,
		.inner-container {
			width: 643px;
			height: 90px;
		}

		.text-bottom {
			max-width: 643px;
			max-height: 110px;
		}

		.text-left {
			max-width: 663px;
			max-height: 90px;
		}
	}

	.mdf-small-rectangle {
		.text-none,
		.inner-container {
			width: 380px;
			height: 103px;
		}

		.text-bottom {
			max-width: 380px;
			max-height: 123px;
		}

		.text-left {
			max-width: 400px;
			max-height: 103px;
		}
	}

	.medium-rectangle {
		.text-none,
		.inner-container {
			width: 300px;
			height: 250px;
		}

		.text-bottom {
			max-width: 300px;
			max-height: 270px;
		}

		.text-left {
			max-width: 320px;
			max-height: 250px;
		}
	}

	.responsive-custom-hero {
		.text-none,
		.inner-container {
			width: 100%;
			height: 400px;
		}

		.outer-container.text-bottom {
			.ad-text {
				margin-right: 15px;
			}
		}
	}

	.responsive-custom-leaderboard {
		.text-none,
		.inner-container {
			width: 100%;
		}

		.outer-container.text-bottom {
			width: 100%;
		}

		.text-left {
			width: 100%;
		}
	}

	.smartphone-tall {
		.text-none,
		.inner-container {
			width: 322px;
			height: 102px;
			border: 1px solid #E4E5E8;
		}

		.text-bottom {
			max-width: 320px;
			max-height: 120px;
		}

		.text-left {
			max-width: 360px;
			max-height: 100px;
		}
	}
	.sv-footer {
		.text-none,
		.inner-container {
			width: 322px;
			height: 102px;
			border: 1px solid #E4E5E8;
		}

		.text-bottom {
			max-width: 320px;
			max-height: 120px;
		}

		.text-left {
			max-width: 360px;
			max-height: 100px;
		}
	}

	.smartphone-wide {
		.text-none,
		.inner-container {
			width: 320px;
			height: 50px;
			outline: 1px solid #E4E5E8;
		}

		.text-bottom {
			max-width: 320px;
			max-height: 70px;
		}

		.text-left {
			max-width: 340px;
			max-height: 50px;
		}
	}

	.thin-custom-leaderboard {
		.text-none,
		.inner-container {
			width: 970px;
			height: 33px;
		}

		.text-bottom {
			max-width: 970px;
			max-height: 53px;
		}

		.text-left {
			max-width: 990px;
			max-height: 33px;
		}
	}

	.wide-skyscraper {
		.text-none,
		.inner-container {
			width: 162px;
			height: 602px;
			border: 1px solid #E4E5E8;
		}

		.text-bottom {
			max-width: 160px;
			max-height: 620px;
		}

		.text-left {
			max-width: 200px;
			max-height: 600px;
		}
	}
	.right-skyscraper {
		.text-none,
		.inner-container {
			width: 160px;
			height: 600px;
			margin: -280px 0 0 10px;
			&.no-margin {
				margin: 20px 0 0 0;
				position: relative;
			}

		}

		.text-bottom {
			max-width: 160px;
			max-height: 620px;
		}

		.text-left {
			max-width: 200px;
			max-height: 600px;
		}
	}

	.vertical-1x2 {
		.text-none,
		.inner-container {
			width: 300px;
			height: 600px;
		}

		.text-bottom {
			max-width: 300px;
			max-height: 620px;
		}

		.text-left {
			max-width: 320px;
			max-height: 600px;
		}
	}
	.category-hero-lv {
		.text-none,
		.inner-container {
			width: 100%;
			height: 400px;
		}

		.outer-container.text-bottom {
			.ad-text {
				margin-right: 15px;
				display: block;
				width: 100%;
				background-color: #ffffff;
				margin-left: 0px;
				padding-right: 50px;
			}
		}
	}
	.category-hero-sv {
		.text-none,
		.inner-container {
			width: 100%;
			height: 245px;
			text-align: center;
		}
		.outer-container.text-bottom {
			.ad-text {
				margin: 0 auto;
				width: 320px;
				display: block;
			}
		}
	}
	.cat-hero-lv-inline {
		.text-none,
		.inner-container {
			width: 644px !important;
			height: 307px !important;
			border: 1px solid #e0e6ef;
		}
		.feedback {
			width: 644px !important;
		}
	}
	.dept-hero-lv-inline {
		.text-none,
		.inner-container {
			width: 978px;
			height: 305px;
		}
	}
	.dotd-sidekick-lv {
		.text-none,
		.inner-container {
			width: 100%;
			height: 0px;
		}
		.outer-container {
			height: 0px;
		}
		.outer-container.text-bottom {
			.ad-text {
				margin-right: 15px;
				width: 100%;
				background-color: #ffffff;
				text-align: right;
				margin-left: 0px;
				padding-right: 50px;
			}
		}
		.ad-text {
			display: none;
		}
	}
	.dotd-sidekick-sv {
		.text-none,
		.inner-container {
			width: 100%;
			height: 0px;
			text-align: center;
		}
		.outer-container {
			height: 0px;
		}
		.outer-container.text-bottom {
			.ad-text {
				margin-right: 15px;
			}
		}
		.ad-text {
			display: none;
		}
	}
	.sidekick-lv {
		.text-none,
		.inner-container {
			width: 980px;
			height: 307px;
			border: 1px solid #C5CBD5;
		}
		.outer-container.text-bottom {
			.ad-text {
				width: 100%;
				background-color: #ffffff;
				text-align: right;
				margin-left: 0px;
			}
		}
	}
	.sidekick-sv {
		.text-none,
		.outer-container {
			width: 322px;
			height: 247px;
			text-align: center;
		}
		.inner-container {
			width: 322px;
			height: 247px;
			text-align: center;
			border: 1px solid #C5CBD5;
		}
	}
	.pencil-sv {
		text-align: center;
		.text-none,
		.inner-container {
			width: 100%;
			height: 44px;
		}
	}

	.pushdown {
		position: relative;
		.text-none,
		.inner-container {
			width: 1008px;
			margin: 0 auto;
			position: relative;
		}

		.ad-hidden {
			display: none;
		}

		.text-bottom {
			max-width: 1008px;
		}

		.ad-background {
			position: absolute;
			width: 100%;
			height: 100%;
		}
		.ad-background > div {
			height: 100%;
		}
		.ad-background iframe {
			width: 100%;
			height: 100%;
		}
	}
	.seen-this {
		.outer-container {
			text-align: right !important;
		}
	}

	.sv-responsive-footer {
		.text-none,
		.inner-container {
			width: 100%;
			max-width: 544px;
			height: 250px;
		}
	}

	.inner-container.panel-lv, .inner-container.panel-sv {
		border: 1px solid #C5CBD5;
		border-radius: 4px;
		overflow: hidden;
	}

	.inner-container.panel-lv {
		border-radius: 0;
	}

	.ballpoint-banner {
		.inner-container {
			width: 1008px;
			height: 50px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		.text-bottom {
			max-width: 1008px;
			max-height: 70px;
		}
	}
}

@media screen and (max-width: 575px) {
	.shop-display-ad .responsive-custom-leaderboard.sv {
		iframe[id^="google_ads_iframe"] {
			height: auto;
			aspect-ratio: 2 / 2.1;
			min-width: 300px;
			max-height: 450px;
		}
		
		.inner-container {
			height: auto;
		}
	}
}

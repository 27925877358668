.shop-display-ad {
	.sidekick-text {
		font-size: 11px;
		font-family: Human BBY Digital;
		color: #70757D;
		margin-right: 4px;
		&:hover {
			color: #2B5DF5;
		}
		&:active {
			color: #001E73;
		}
	}
	.feedback {
		display: inline-block;
		margin-top: 4px;
	}
}
